/* ----------------------- Common desktop header ----------------------- */
.header {
    position: absolute;
    transition: background ease-in-out 0.25s, height ease-in-out 0.25s;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    height: 120px;
    padding: 25px 0;
    display: flex;
    align-items: center;

    &.active {
        .cont {
            background: transparent;
        }
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 158px;
        transition: transform 0.3s ease;

        img:first-child {
            margin-right: 6px;
        }

        &:hover {
            img:first-child {
                animation: rotate 4s infinite;
            }
        }
    }

    .cont {
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: setTransition(background);

        background: rgba(38, 80, 188, 0.6);
        padding: 10px;
        border-radius: 40px;
    }

    .logo {
        height: 100%;
        width: ac(160px, 144px);
        flex-shrink: 0;
        transition: setTransition(transform);
        transform: scale(0.8);

        &:hover {
            transform: scale(0.85);
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        /* .menu-link {
      &:first-of-type {
        padding-left: 20px;
      }

      &:last-of-type {
        padding-right: 20px;
      }
    }*/

        span {
            position: absolute;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            background: var(--dark-blue);

            height: 100%;

            z-index: -1;
            opacity: 0;

            left: var(--left-position-span);
            width: var(--width-span);
            transition: var(--span-transition);

            &.active {
                opacity: 1;
            }
        }

        @mixin tab {
            display: none;
        }
    }

    .btn {
        background: var(--white);
        color: var(--blue);

        @mixin tab {
            display: none;
        }
    }

    .menu {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    .menu-item {
        margin-right: 20px;
        position: relative;
    }

    .menu-link {
        position: relative;
        font-size: 15px;
        padding: 10px;
        @mixin transition-all;

        &:not(:last-child) {
            margin-right: 22px;
        }

        @mixin desk-xl {
            font-size: 18px;
        }
    }
}

/* end of Common desktop header */

/* ----------------------- ONLY Mobile HEADER ----------------------- */

.mobile-header {
    position: fixed;
    right: 0;
    top: 0;
    width: 50%;
    height: 100vh;
    min-width: 320px;
    padding: ac(190px, 130px) 0;

    display: flex;
    justify-content: center;

    background: linear-gradient(
        180deg,
        rgba(8, 199, 87, 1) 0%,
        rgba(0, 162, 255, 1) 100%
    );

    z-index: 999;
    transition: transform 0.3s ease-out;

    .mobile-navbar {
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 2px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: var(--grey);
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: var(--white);
        }
    }

    .menu {
        width: 100%;
        max-height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0 25px;

        &::-webkit-scrollbar {
            width: 3px;
            height: 2px;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(255, 255, 255, 0.5);
            border-radius: 3px;
            cursor: pointer;
        }
    }

    .menu-item:not(:last-child) {
        margin-bottom: 35px;
    }

    .menu-link {
        font-size: 28px;
        position: relative;
        line-height: initial;

        &:before {
            background: var(--white);
        }
    }

    a:not(.logo, .socials a) {
        @mixin hover-link var(--white);
    }

    &.slide-right {
        right: 0;
        left: auto;
        transform: translateX(100%);

        &.active {
            transform: translateX(0);
        }
    }

    @mixin mob-lg {
        width: 100%;
    }
}

/* end of ONLY Mobile HEADER */

/* ----------------------- HEADER COMPONENTS ----------------------- */

.burger {
    width: 50px;
    height: 50px;

    position: relative;
    cursor: pointer;
    display: none;

    justify-content: center;
    align-items: center;
    border-radius: 50%;

    background: var(--blue);

    span {
        position: relative;
        top: 0;
        left: 0;
        background: var(--white);
        height: 2px;
        width: 21px;
        transition: all 0.2s ease;

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: -5px;
            background: white;
            width: 21px;
            height: 2px;
            transition: all 0.2s ease;
        }

        &:after {
            top: 5px;
        }
    }

    @mixin transition-all;

    @mixin tab {
        display: flex;
    }

    &.active {
        background: var(--white);
        span {
            background: var(--white);

            &::before {
                background: var(--blue);
                top: 0;
                transform: rotate(-45deg);
            }

            &::after {
                background: var(--blue);
                top: 0;
                transform: rotate(45deg);
            }
        }
    }
}

.header-close-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 77;
    background: rgba(0, 0, 0, 0.7);
    pointer-events: none;
    visibility: hidden;
    opacity: 0;

    @mixin transition-all;

    &.active {
        visibility: visible;
        pointer-events: auto;
        opacity: 1;
        cursor: pointer;
    }
}

/* end of HEADER COMPONENTS */
