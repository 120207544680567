/* About section  */
.about-section {
    background: var(--purple);
    padding: 70px 0 ac(90px, 15px);
    position: relative;
    z-index: 11;

    .star-img {
        position: absolute;
        size: 48.6vw;
        right: ac(0px, -50px);
        top: ac(-290px, -240px, 1024, 1440);

        /* For Safari */
        transform: translateZ(0);
        will-change: transform;

        @mixin min-media 1440 {
            size: 705px;
        }

        @mixin media 1280 {
            size: 43vw;
        }

        @mixin tab-sm {
            size: ac(390px, 300px, 320, 768);
            top: -220px;
        }
    }

    .heading-section {
        max-width: ac(580px, 420px, 768, 1440);

        .title {
            margin-bottom: 10px;
        }

        .content-element {
            h3 {
                font-size: ac(18px, 16px);
                margin-bottom: 6px;
            }

            p {
                font-size: ac(16px, 14px);
            }
        }

        @mixin desk-md {
            max-width: 700px;
        }
    }

    .content-wrap {
        margin-top: 70px;
        align-items: center;

        .images {
            display: flex;
            align-items: center;
            position: relative;
            flex: 0 0 auto;
            margin-right: 120px;

            .about-img {
                width: 32vw;
                height: 23.6vw;
                border-radius: 18px;
                @mixin img-fit;

                img {
                    border-radius: 18px;
                }

                @mixin desk-md {
                    height: 340px;
                    width: 460px;
                }
            }

            .star-item {
                position: absolute;
                width: 25vw;
                height: 100%;
                left: -40%;
                transform: rotate(-11deg);

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        .content-element {
            max-width: 595px;

            p {
                font-size: 16px;
                line-height: 25px;
            }
        }

        @mixin media 1150 {
            flex-direction: column;

            .images {
                margin: 0 0 30px;

                .about-img {
                    max-width: 500px;
                    width: 100%;
                    height: auto;
                }

                .star-item {
                    width: 50%;
                    height: 100%;
                    left: -25%;
                }
            }

            .content-element {
                max-width: 100%;
            }
        }
    }

    .decor-element {
        right: 0;
        bottom: -80px;
        transform: rotate(-11deg);
    }

    &.candidates {
        padding-bottom: ac(115px, 50px);
        min-height: 881px;

        .decor-element {
            bottom: ac(15px, -90px);
            right: ac(20px, 15px);
        }
    }

    &.clients {
        padding-bottom: ac(120px, 0px);
    }
}

/* Goals section */
.goals {
    &:before {
        background: url("../images/waves/wave-purple-top.svg") repeat-x;
    }

    .star-bg {
        opacity: 0.18;
    }

    .section {
        padding: ac(218px, 130px) 0 75px;
    }

    .vision {
        .star-bg {
            transform: scale(1.7) rotate(11deg);
            left: -30%;
            top: -10%;
        }
    }

    .purpose {
        .star-bg {
            transform: scale(1.7) rotate(-18deg);
            right: -30%;
            left: auto;
        }
    }

    .content-element {
        p {
            font-size: ac(16px, 14px);
            line-height: 20px;
        }
    }
}

/* Careers section */
.careers {
    padding: ac(130px, 100px) 0 90px;

    .heading-section {
        margin-bottom: 30px;
        max-width: ac(580px, 420px, 901, 1440);
    }

    &-images {
        max-width: 1160px;

        .img-wrap {
            display: grid;
            grid-gap: 20px;

            .img-item {
                @mixin img-fit;
            }

            &:nth-child(1) {
                grid-template-columns: 24vw 22vw 32vw;

                .img-item {
                    &:nth-child(1) {
                        height: 29vw;
                    }

                    &:nth-child(2) {
                        height: 16vw;
                    }

                    &:nth-child(3) {
                        height: 23.6vw;
                        margin-top: -210px;
                    }
                }
            }

            &:nth-child(2) {
                margin-top: ac(-170px, -100px, 901, 1440);
                grid-template-columns: 28vw 22vw;
                justify-content: end;

                .img-item {
                    &:nth-child(1) {
                        height: 19.5vw;
                    }

                    &:nth-child(2) {
                        height: 16vw;
                        margin-top: ac(-100px, -140px, 901, 1440);
                    }
                }
            }

            @mixin min-media 1440 {
                &:nth-child(1) {
                    grid-template-columns: 350px 310px 460px;

                    .img-item {
                        &:nth-child(1) {
                            height: 420px;
                        }

                        &:nth-child(2) {
                            height: 228px;
                        }

                        &:nth-child(3) {
                            height: 340px;
                        }
                    }
                }

                &:nth-child(2) {
                    grid-template-columns: 406px 310px;

                    .img-item {
                        &:nth-child(1) {
                            height: 280px;
                        }

                        &:nth-child(2) {
                            height: 228px;
                        }
                    }
                }
            }
        }
    }

    &-img-swiper {
        .swiper-wrapper {
            display: flex;
        }

        .swiper-slide {
            max-width: 400px;
            height: 300px;
            @mixin img-fit;
        }
    }

    img {
        border-radius: 18px;
    }
}

/* Benefits section */
.benefits {
    padding: ac(100px, 70px) 0;
    background: var(--purple);

    .content-wrap {
        text-align: center;
        justify-content: center;
        margin-bottom: 30px;

        @mixin desk-sm {
            margin-bottom: 30px;
        }
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 1000px;
        margin: 0 auto;

        @mixin media 415 {
            flex-direction: column;
        }
    }

    &-item {
        max-width: 146px;
        width: 100%;
        text-align: center;
        flex: 0 0 calc(20% - 20px);
        margin: 10px;

        p {
            font-size: ac(16px, 14px);
        }

        @mixin media 991 {
            flex-basis: calc(33.3% - 20px);
        }

        @mixin media 615 {
            flex-basis: calc(50% - 20px);
        }

        @mixin media 415 {
            max-width: 100%;
            margin: 0 auto;

            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }

    &-img {
        width: 100%;
        height: 146px;
        background: var(--purple);
        border-radius: 25px;
        margin-bottom: 10px;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
            border-radius: 25px;
            width: 90%;
            height: 90%;
            object-fit: contain;
        }

        @mixin media 415 {
            max-width: 146px;
            margin: * auto;
        }
    }
}

.careers-img-swiper {
    display: none;

    @mixin desk-sm {
        display: block;
    }
}
