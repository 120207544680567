.contact-us {
    padding: ac(185px, 130px) 0 ac(265px, 130px);

    /* Bg */
    &:after {
        background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 1) 55%
            ),
            url(../images/green-star-bg.png) no-repeat top / cover fixed;
        opacity: 0.8;
        height: 1000px;
    }

    .contacts-block {
        background: var(--purple);
    }

    .contact-wrap {
        justify-content: space-between;
        z-index: 15;
    }

    .contacts-block {
        margin: 0;
    }

    .decor-items {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 200px;

        @mixin tab {
            position: absolute;
        }
    }

    .wave {
        width: ac(835px, 200px);

        &:first-child {
            bottom: ac(-215px, -30px);
        }

        &:nth-child(2) {
            bottom: ac(-265px, -55px);
            width: ac(835px, 230px);
        }

        &:nth-child(3) {
            bottom: ac(-255px, -35px);
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }

        @mixin min-media 1536 {
            bottom: auto !important;
            &:first-child {
                width: 80%;
                left: -10%;
            }

            &:nth-child(2) {
                width: 80%;
            }

            &:nth-child(3) {
                width: 45%;
                right: -5%;
            }
        }

        @mixin tab {
            position: absolute;
        }
    }

    .bottom-info {
        position: absolute;
        bottom: 20px;
        z-index: 11;
        color: var(--white);

        max-width: 1292px;
        width: perc(1292);

        a {
            color: var(--white);
        }

        .wrap {
            display: flex;
            align-items: center;

            @mixin mob {
                flex-direction: column;
            }
        }

        @mixin media 360 {
            #bold-credits {
                margin-left: 10px;
                width: 110px;
            }
        }
    }

    @mixin min-media 1440 {
        height: 100vh;
    }

    @media only screen and (max-height: 900px) {
        height: 100% !important;
    }

    @media only screen and (min-width: 1920px) and (min-height: 900px) {
        padding: 0;
        display: flex;
        align-items: center;
    }
}
