/* ----------------------- Buttons ----------------------- */

.btn {
    position: relative;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    min-width: ac(150px, 125px);
    height: ac(50px, 43px);
    width: fit-content;

    font-size: ac(15px, 12px);
    border-radius: 25px;

    background: var(--blue);
    transition: all 0.3s ease-in-out;
    line-height: initial;

    @mixin safari-overflow;

    span {
        z-index: 5;
        position: relative;
        pointer-events: none;
    }

    &::after {
        position: absolute;
        content: "";
        top: 140%;
        left: 50%;
        width: 200px;
        height: 200px;
        background: var(--light-blue);
        margin-left: -100px;
        border-radius: 42%;
        transform-origin: 50% 50%;
        transition: top 0.6s ease-in-out;
    }

    &:hover {
        &::after {
            animation: wave-btn 2s infinite linear;
            top: 30%;
        }
    }

    &:active {
        &::after {
            top: -100%;
        }
    }

    &.ghost {
        background: transparent;
        border: 1px solid var(--light-blue);
        color: var(--dark-blue);
        &::after {
            display: none;
        }

        &:hover {
            color: var(--white);
            background: var(--light-blue);
        }
    }

    &.job-btn {
        font-weight: 700;
    }

    @mixin desk-xl {
        font-size: 18px;
        min-width: 185px;
    }
}
