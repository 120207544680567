/* Types of roles */

.roles {
  margin-top: 90px;

  h2 {
    margin-bottom: 44px;
  }

  &-wrap {
    display: flex;
  }

  &-list {
    max-width: ac(420px, 270px, 768, 1440);
    width: 100%;
    flex: 0 0 auto;

    &:first-child {
      margin-right: 60px;
    }
  }

  &-item {
    position: relative;
    padding-left: 18px;



    h3 {
      text-align: left;
      font-size: ac(22px, 20px);
      color: var(--dark-blue);
      transition: color 0.1s ease;
    }

    p {
      line-height: 25px;
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &.is-open {
      h3 {
        color: var(--blue);
      }

      .trigger-img {
        &:before, &:after {
          background: var(--blue);
        }
      }
    }
  }

  &-trigger {
    button {
      position: relative;
    }

    .trigger-img {
      position: absolute;
      left: -25px;
      top: 13px;
    }
  }

  @mixin tab-sm {
    &-wrap {
      flex-direction: column;
    }

    &-list {
      max-width: 100%;
      &:first-child {
        margin: 0 0 14px;
      }
    }
  }
}
