.footer {
    position: relative;
    padding: ac(110px, 95px) 0 ac(40px, 24px);
    background: var(--purple);

    z-index: 20;

    a {
        position: relative;
    }

    &-form {
        max-width: 540px;
        width: 100%;
        margin-right: 40px;

        h2 {
            margin-bottom: ac(34px, 20px);
        }

        label {
            font-size: 10px;
            color: var(--blue);
            font-weight: 600;
        }

        .inputs-wrap {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: ac(34px, 22px);

            .input-item {
                &:last-child {
                    grid-column: -1 / 1;
                }
            }

            @mixin mob-lg {
                grid-template-columns: 1fr;

                textarea {
                    height: 34px;
                }
            }
        }

        .agreement {
            max-width: 540px;
            margin: 28px 0 ac(26px, 22px);

            .checkbox:not(:last-child) {
                margin-bottom: ac(7px, 2px);
            }
        }

        @mixin desk-xl {
            max-width: 590px;

            label {
                font-size: 14px;
            }

            .agreement {
                max-width: 100%;
            }
        }
    }

    .socials {
        margin-top: ac(20px, 15px);
    }

    /* UI */
    .wave {
        &:first-child {
            top: ac(-105px, -25px);

            @mixin min-media 1536 {
                width: 45vw;
                top: -160px;
            }
        }

        &:nth-child(2) {
            top: ac(-140px, -25px);

            @mixin min-media 1536 {
                top: -25%;
                width: 85vw;
            }
        }

        &:nth-child(3) {
            top: ac(-175px, -35px);

            @mixin min-media 1536 {
                width: 45vw;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    .bg {
        position: absolute;
        top: ac(-80px, 0px);
        left: -5%;
        width: 110%;
        min-height: ac(394px, 105px);
        z-index: 5;
        pointer-events: none;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    /* Breakpoints */

    @mixin desk-lg {
        padding-top: 150px;
    }

    @mixin tab {
        .content-wrap {
            flex-direction: column;
        }

        &-form {
            margin: 0 0 80px;
            max-width: 100%;
        }

        .contacts-block {
            max-width: 600px;
            width: 100%;

            p {
                max-width: 300px;
            }

            .content-wrap {
                flex-direction: row;
            }

            .contacts-list {
                display: flex;
                flex-wrap: wrap;

                li:not(:last-child) {
                    margin-right: 40px;
                }
            }

            a:before {
                background: var(--dark-blue);
            }
        }

        .bottom-info {
            justify-content: center;
        }
    }

    @mixin mob-md {
        .contacts-list {
            flex-direction: column;
        }
    }
}

.bottom-info {
    display: flex;
    align-items: center;
    margin-top: ac(80px, 20px);
    position: relative;
    z-index: 10;

    @mixin media 360 {
        &:not(.contact-us .bottom-info) {
            flex-direction: column;

            a,
            p {
                margin: 0 0 10px;
            }
        }
    }

    p,
    a {
        padding: 0;
        font-size: 10px;
        line-height: 110%;

        @mixin desk-xl {
            font-size: 14px;
        }
    }

    a {
        color: var(--dark-blue);
        margin-left: 10px;
        font-weight: 500;

        &:not(#bold-credits) {
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

/* Contacts Block */
.contacts-block {
    background: var(--white);
    border-radius: 25px;
    padding: 30px 30px ac(38px, 28px);
    margin-top: -24px;
    max-width: 380px;
    height: 100%;
    flex: 0 0 auto;

    .logo {
        width: ac(88px, 72px);
        flex-shrink: 0;
        margin-right: ac(27px, 16px);
        @mixin transition-all;
        &:hover {
            transform: scale(1.05);
        }
    }

    p {
        max-width: 200px;
        line-height: 130%;
    }

    .content-wrap {
        margin-bottom: 30px;
        align-items: center;
    }

    h4 {
        color: var(--blue);
        font-size: ac(15px, 12px);
        margin-bottom: 7px;
    }

    .socials {
        margin-top: 15px;
    }

    .contacts-list {
        li {
            a {
                color: var(--dark-blue);
                font-size: ac(16px, 13px);

                &:before {
                    background: var(--dark-blue);
                }
            }

            &:not(:last-child) {
                margin-bottom: 14px;
            }
        }
    }

    @mixin desk-xl {
        max-width: 390px;

        h4 {
            font-size: 18px;
        }

        .contacts-list {
            li a {
                font-size: 15px;
            }
        }
    }
}

.contact-wrap {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 10;

    @mixin tab {
        flex-direction: column;
    }
}

/* Waves */
.wave {
    position: absolute;
    z-index: 3;
    width: ac(800px, 200px);
    pointer-events: none;

    &:first-child {
        left: ac(-35px, -10px);
    }

    &:nth-child(2) {
        left: ac(355px, 60px);
        width: ac(800px, 230px);

        @mixin min-media 1536 {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &:nth-child(3) {
        right: ac(-35px, -25px);
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }
}
