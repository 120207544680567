.fp-watermark {
    display: none;
}

body {
    position: relative;
    min-width: 320px;
    margin: auto;
    background: var(--white);
    color: var(--dark-blue);
    font-family: var(--font-main);

    &.disabled {
        overflow-y: scroll;
        position: fixed;

        width: 100%;
    }
}

.swiper-wrapper {
    left: 0 !important;
}

#site {
    position: relative;
    overflow: hidden;
    min-height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.main {
    flex-grow: 1;
}

/* ---- Specification ---- */

.cont {
    margin: 0 auto;
    max-width: 1160px;
    width: perc(1160);

    &.lg {
        max-width: 1292px;
        width: perc(1292);
    }

    @mixin tab-md {
        width: 81% !important;
    }

    @mixin desk-xl {
        max-width: 1240px;
    }
}

section {
    position: relative;
}

.first-section {
    min-height: ac(900px, 590px, 375, 768);
    display: flex;
    align-items: center;
}

.interior-page {
    min-height: ac(600px, 465px);
    text-align: center;

    h1 {
        color: var(--white);
    }

    &.wavy {
        &:before {
            transform: rotate(180deg);
            background: url("../images/waves/wave-purple-top.svg") repeat-x;
            top: auto;
            bottom: -4px;
            height: 205px;
        }
    }

    @mixin media 651 {
        display: block;
        padding-top: 200px;
    }
}

.single-page {
    padding: ac(265px, 150px) 0 ac(350px, 150px);

    h1:not(.content-element h1) {
        text-align: center;
    }

    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 105%;
        height: 930px;
        z-index: -1;
        pointer-events: none;
        background-attachment: fixed;
    }
}

.clip-elements {
    position: absolute;
    z-index: -999;
}

.star-img {
    clip-path: url(#star-clip);
    z-index: 10;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.wavy {
    &::before {
        content: "";
        position: absolute;
        top: -55px;
        height: 200px;
        pointer-events: none;
        left: 0;
        width: calc(1440px * 4);
        z-index: 10;
        animation: wave-section 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    }

    .cont {
        position: relative;
        z-index: 15;
    }

    &.wavy-blue {
        &::before {
            background-image: url("../images/waves/wave-blue-lg.svg");
        }
    }

    &.wavy-white {
        &::before {
            background: url("../images/waves/1440_white.svg") repeat-x;
        }
    }
}

.heading-section {
    max-width: 580px;

    h2 {
        margin-bottom: 10px;
    }
}

.contacts {
    display: flex;
    flex-direction: column;
}

.tab-trigger {
    button {
        display: flex;
        align-items: center;
        width: fit-content;
    }
}

/* Socials */
.socials {
    &-list {
        display: flex;
        align-items: center;

        li:not(:last-child) {
            margin-right: 11px;
        }
    }

    a {
        height: ac(27px, 24px);
        width: ac(27px, 24px);
        padding: 1px;
        color: var(--white);

        display: flex;
        align-items: center;
        justify-content: center;

        background: var(--light-blue);
        border-radius: 5px;

        i {
            font-size: 15px;
            color: var(--white);
        }

        transition: border-radius 0.25s ease;

        &:hover {
            border-radius: 50%;
        }
    }
}

/* Decor items */

.decor-element {
    position: absolute;
    z-index: 15;
    pointer-events: none;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transform: scale(1.1);
    }

    &.sm {
        width: 25vw;
        height: 22vw;

        @mixin mob-lg {
            height: 30vw;
            width: 45vw;
        }

        @mixin min-media 1440 {
            width: 330px;
            height: 310px;
        }
    }
}

/* Backgrounds */
.green-blue {
    background: url("../images/blue-green-bg.png") no-repeat center / cover;
}

.green-star {
    &:after {
        height: 100%;
        background: url("../images/green-star-bg.png") no-repeat top / 100%;
    }
}

.purple-blue {
    background: url("../images/blue-purple-bg.png") no-repeat center / cover;
}

.purple-star {
    &:after {
        height: 100%;
        background: url("../images/purple-star-bg.png") no-repeat top / 100%;
    }
}
/* ---- Main Page ---- */
.sections-wrap {
    background: url("../images/main-bg.png") no-repeat center / cover;
}

/*  First screen  */
.main-page {
    display: block;
    padding: ac(250px, 190px) 0 170px;

    h1 {
        animation: focus-in 0.8s ease-in-out both;
    }

    @mixin media 901 {
        min-height: 0;
    }

    .decor-element {
        width: 50vw;
        height: 55vw;
        right: ac(-70px, -120px, 375, 551);
        transform: rotate(11deg);
        bottom: 0;

        @mixin min-media 1440 {
            width: 700px;
            height: 790px;
        }

        @mixin media 901 {
            top: calc(100% - 280px);
            bottom: auto;
        }

        @mixin media 651 {
            top: 75%;
        }

        @mixin mob-lg {
            width: ac(332px, 250px, 320, 375);
            height: 275px;
        }
    }

    @mixin mob-lg {
        background-position: 30%;
    }
}

.offer {
    color: var(--white);
    max-width: ac(600px, 520px, 1025, 1440);
    position: relative;
    z-index: 10;
    transform: translateY(-3vh);

    h1 {
        margin-bottom: ac(41px, 20px);
        @mixin text-outline;
    }

    @mixin tab-sm {
        transform: translate(0);
    }
}

/* About section */
.about {
    padding: ac(100px, 205px, 1025, 1440) 0 220px !important;

    display: flex;
    align-items: center;

    position: relative;
    z-index: 12;

    &-content {
        max-width: 575px;
        margin-right: 10%;
        color: var(--white);

        .content-element {
            p {
                margin: ac(10px, 4px) 0 18px;

                @mixin desk-xl {
                    margin: 20px 0;
                }
            }

            li:before {
                background: var(--white);
            }
        }

        @mixin media 1200 {
            margin-right: 0;
        }

        @mixin media 1025 {
            max-width: 100%;
        }
    }

    .cont {
        display: flex;
        justify-content: flex-end;
    }

    .decor-element {
        width: 36vw;
        height: 34vw;
        left: 20px;
        top: -10%;
        transform: rotate(-11deg) rotateY(-180deg);

        @mixin min-media 1920 {
            left: 5%;
            width: 690px;
            height: 750px;
        }

        @mixin media 1025 {
            top: auto;
            bottom: 82%;
        }

        @mixin media 651 {
            bottom: 90%;
        }
    }
}

/* Double section */
.pages {
    &:before {
        background: url("../images/waves/1440_white.svg") repeat-x;
    }

    .section:hover {
        img {
            transform: scale(3);
        }
    }
}

.double-section {
    .content-wrap {
        overflow: hidden;

        .section:last-child {
            padding-bottom: ac(180px, 200px);
        }

        @mixin tab-md {
            flex-direction: column;
        }
    }

    &:before {
        top: ac(-56px, -70px, 901, 1440);
    }

    &:after {
        content: "";
        position: absolute;
        z-index: 10;

        height: 200px;
        pointer-events: none;
        left: 0;
        width: calc(1440px * 4);
        bottom: -2px;
        display: none;

        transform: rotate(180deg);
        background: url("../images/waves/1440_white.svg") repeat-x;
        animation: wave-section 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    }

    .content-element {
        ul li {
            &:before {
                background: var(--white);
            }
        }
    }

    @mixin tab-md {
        clip-path: none;

        .content-wrap {
            flex-direction: column;
        }

        &:after {
            display: block;
        }
    }
}

.content-wrap {
    display: flex;
    width: 100%;
}

section:last-of-type {
    &:not(.double-section, .testimonials, .insights-section, .contact-us) {
        padding-bottom: ac(360px, 100px);
    }

    &.insights-section {
        padding-bottom: ac(200px, 100px);
    }

    &.section {
        padding-bottom: 200px;
    }
}

.section {
    color: var(--white);
    padding: ac(178px, 75px) 0 ac(55px, 70px);
    position: relative;
    overflow: hidden;
    width: 100%;

    display: flex;
    justify-content: center;

    h2 {
        margin-bottom: ac(25px, 10px);
    }

    .subtext {
        margin: 7px 0 ac(20px, 7px);
        font-weight: 400;
        font-size: ac(16px, 13px);

        p {
            margin: 7px 0 ac(20px, 7px);
            font-weight: 400;
            font-size: ac(16px, 13px);
        }

        @mixin desk-xl {
            font-size: 18px;

            p {
                font-size: 18px;
            }
        }
    }

    .content {
        z-index: 5;
        position: relative;
        max-width: 440px;

        @mixin tab-md {
            max-width: 460px;
            width: 81%;
            margin: 0 auto;
        }
    }

    .star-bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        pointer-events: none;

        img {
            width: 100%;
            height: 100%;
            transform: scale(1.3);
            overflow: visible;
            transition: transform 3s ease-in-out;
        }
    }

    &:nth-child(1) {
        background: var(--light-blue);
        @mixin tab-md {
            padding-top: ac(155px, 160px, 375, 551);
        }
    }

    &:nth-child(2) {
        background: var(--blue);
        position: relative;
        @mixin tab-md {
            padding-bottom: 135px;
        }

        @mixin mob-lg {
            .star-bg {
                transform: rotate(25deg);
            }
        }

        @mixin mob {
            .star-bg {
                top: 0;
            }
        }
    }

    &.clients {
        .star-bg {
            mix-blend-mode: overlay;
            left: -125px;
            top: -30px;
        }
    }

    &.candidates {
        .star-bg {
            mix-blend-mode: hard-light;
            opacity: 0.7;
            transform: rotate(18deg);
            left: ac(-30px, 30px, 375, 551);
            top: ac(-30px, -20px);
        }
    }

    @mixin desk-xl {
        padding-bottom: 100px;

        .content {
            max-width: 500px;
        }
    }
}

/* 404 and Policy */
.single-page-404 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.terms-section {
    background: var(--purple);
    z-index: 15;
}

/* Bold Credits */
.bold-logo-wr {
    display: flex;
    justify-content: flex-start;
    position: relative;
    z-index: 20;

    @mixin tab {
        justify-content: center;
    }
}

#bold-credits {
    width: ac(150px, 120px);
    transition: all 0.3s;
    margin-left: ac(40px, 20px);

    @mixin media 360 {
        margin-left: 0;
    }

    &::before {
        display: none;
    }

    &:hover {
        transform: scale(1.14);
    }
}

/* Recaptcha */

.grecaptcha-badge {
    z-index: 1000;
}
