.pagination {
  &-list {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: fit-content;
  }

  &-item {
    position: relative;
    display: inline-block;

    span,
    a {
      color: var(--dark-blue);
      font-size: 15px;
    }
  }

  &-btn {
    opacity: 0.5;
    transition: opacity 0.25s ease-in-out;
    display: flex;
    align-items: center;

    span {
      color: var(--blue);
      &:first-child {
        margin-right: 4px;
      }

      @mixin mob-xl {
        &:not(.arrow) {
          display: none;
        }
      }
    }

    &:hover {
      opacity: 1;
    }
  }

  &-numbers {
    margin: 0 20px 0 32px;

    .pagination-item:not(:last-child) {
      margin-right: 7px;
    }

    .dots {
      opacity: 0.27;
      transition: opacity 0.25s ease-in-out;
      &:hover {
        opacity: 1;
      }
    }
  }

  &-num {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    transition: all 0.25s ease-in-out;

    &:not(.dots):before {
      content: "";
      display: block;
      position: absolute;
      width: 32px;
      height: 32px;
      border: 1px solid var(--light-blue);
      border-radius: 50%;
      opacity: 0;
      transition: opacity 0.25s ease-in-out;
    }

    &.active {
      span {
        color: var(--light-blue);
        font-weight: 600;
      }
    }

    &:not(.dots):hover {
      margin: 0 15px;
      &:before {
        opacity: 1;
      }
    }
  }
}
