h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
}

p,
a {
    font-family: var(--font-secondary);
}

span,
a {
    display: inline-block;
}

h1 {
    font-size: ac(65px, 34px);
    line-height: 120%;
    position: relative;
}

h2 {
    font-size: ac(50px, 28px);
}

h3 {
    font-size: ac(30px, 24px);
}

p {
    font-size: ac(18px, 15px);
    line-height: ac(28px, 19px);
    font-weight: 500;
}

a,
button {
    font-size: ac(16px, 13px);
    color: var(--white);
}

.sm,
.sm p {
    font-size: ac(16px, 14px);
    line-height: 130%;
}

/* Hover links */

.mobile-header,
.contacts-list,
.contacts {
    a:not(.logo, .socials a) {
        @mixin hover-link var(--dark-blue);
    }
}

@mixin desk-xl {
    h2 {
        font-size: 54px;
    }

    p {
        font-size: 22px;
    }
}

.title {
    /*white-space: nowrap;*/

    span {
        opacity: 0;
        transform: translateY(4em);
        transition: transform 0.2s ease-in-out, opacity 0.6s ease-in-out;
    }

    &.show {
        span {
            transform: translateY(0);
            opacity: 1;
        }
    }
}

.content-element {
    * {
        &:last-child {
            margin-bottom: 0;
        }
    }

    h1,
    h2 {
        &:not(:last-child) {
            margin-top: 20px;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 10px;
    }

    h1 {
        font-size: ac(30px, 24px);
    }

    h2 {
        font-size: ac(22px, 20px);
    }

    h3 {
        font-size: ac(20px, 18px);
    }

    h4 {
        font-size: ac(18px, 16px);
    }

    h5 {
        font-size: ac(16px, 14px);
    }

    h6 {
        font-size: ac(14px, 12px);
    }

    a {
        color: var(--light-blue);
        font-size: ac(16px, 14px);
        font-weight: 600;
        font-family: var(--font-main);
        display: inline;

        &:hover {
            text-decoration: underline;
        }
    }

    li {
        font-size: ac(16px, 14px);
    }

    ul,
    ol {
        margin-bottom: 16px;

        li {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }

    ol {
        list-style: decimal;
        padding-left: 16px;
    }

    ul li {
        padding-left: 18px;
        position: relative;

        &:before {
            content: "";
            display: block;
            position: absolute;

            top: 8px;
            left: 0;

            size: 8px;
            border-radius: 50%;
            background: var(--dark-blue);
            transition: background 0.1s ease;
        }
    }

    &:not(.main-content) {
        p {
            font-size: ac(16px, 14px);
            margin-bottom: 14px;
            line-height: 150%;
        }
    }

    &.main-content {
        font-size: ac(16px, 14px);
        line-height: 150%;
        font-weight: 500;
        font-family: var(--font-secondary);
    }
}
