input,
textarea {
    font-family: var(--font-secondary);
    font-size: ac(15px, 12px);

    border-radius: 0;
    background: transparent;
    padding-bottom: 10px;
    color: var(--dark-blue);
    border-bottom: 1px solid var(--dark-blue);

    position: relative;
    resize: none;
    transition: all 0.3s ease-in-out;
    width: 100%;

    @mixin transparent-bg-input var(--dark-blue);

    &::placeholder {
        font-weight: 600;
        font-family: var(--font-main);
        color: var(--blue);
        font-size: ac(15px, 12px);
    }

    &::-webkit-scrollbar {
        width: 2px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: var(--grey);
    }

    @mixin desk-xl {
        font-size: 18px;

        &::placeholder {
            font-size: 18px;
        }
    }
}

.input-item {
    position: relative;
    width: 100%;

    label {
        position: absolute;
        top: 0;
        opacity: 0;
        transition: setTransition(top, opacity);
    }

    &.focus {
        label {
            top: -22px;
            opacity: 1;

            @mixin desk-xl {
                top: -20px;
            }
        }

        input,
        textarea {
            &::placeholder {
                opacity: 0;
            }
        }
    }
}

/* Custom checkbox */
.checkbox {
    grid-column: 1 / -1;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: fit-content;

    span {
        padding-left: ac(16px, 28px);
        position: relative;
        font-size: 12px;
        color: var(--dark-blue);
        text-align: left;

        @mixin transition-all 0.3s;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            size: 11px;

            border: 1px solid var(--dark-blue);
            left: 0;
            margin: auto;

            font-size: 11px;
            overflow: visible;
            transition: all 0.3s ease;

            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;

            @mixin mob-lg {
                size: 14px;
            }
        }

        a {
            text-transform: none;
            text-decoration: underline;
            color: var(--dark-blue);
            font-size: 12px;

            &:hover {
                color: var(--blue);
            }
        }
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        width: fit-content;

        &:checked + span {
            &:before {
                background-color: var(--dark-blue);
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 12L10 18L20 6' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            }
        }
    }

    @mixin desk-xl {
        span {
            font-size: 14px;

            a {
                font-size: 14px;
            }
        }
    }
}

/* Select 2 */
select {
    visibility: hidden !important;
    opacity: 0 !important;
}

.select-item {
    select {
        display: none;
    }
}

.select2-container--default {
    .select2-selection {
        border: none !important;

        .select2-selection__rendered {
            color: var(--blue);
            text-overflow: inherit;
            line-height: inherit;
            padding-right: 0px;
        }

        .select2-selection__placeholder {
            color: var(--blue);
        }

        .select2-selection__arrow {
            top: -3px;
            right: 30px;
            transition: transform 0.25s ease-in-out;

            b {
                border-style: none;
                border-color: unset;

                &:before {
                    content: "";
                    display: block;
                    width: 12px;
                    height: 8px;
                    mask-repeat: no-repeat;
                    mask-size: contain;
                    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5.788' height='3.601' viewBox='0 0 5.788 3.601'%3E%3Cpath id='%D0%9A%D0%BE%D0%BD%D1%82%D1%83%D1%80_244' data-name='%D0%9A%D0%BE%D0%BD%D1%82%D1%83%D1%80 244' d='M7224.468 546.781l2.54 2.54 2.541-2.54' transform='translate(-7224.114 -546.427)' fill='none' stroke='%232650bc' stroke-width='1'/%3E%3C/svg%3E");
                    background-color: var(--blue);
                }
            }
        }

        &.select2-selection--multiple {
            .select2-search__field {
                margin: 0;
                width: 100% !important;
                overflow: visible;
                height: 19px;
                white-space: nowrap;

                font-weight: 500;
                color: var(--dark-blue);
                font-family: var(--font-secondary);

                &::placeholder {
                    color: var(--dark-blue);
                    font-weight: 500;
                    font-family: var(--font-secondary);
                    font-size: 15px;
                    text-overflow: ellipsis;
                }

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            .select2-search--inline {
                display: flex;
                align-items: center;
                width: 100%;
            }

            .select2-selection__rendered {
                display: flex;
                align-items: center;

                .select2-selection__choice {
                    margin: 0;
                    background-color: var(--purple);
                    border-color: var(--light-blue);
                    border-radius: 16px;

                    &:not(:last-child) {
                        margin-right: 8px;
                    }

                    &__remove {
                        color: var(--dark-blue);
                    }
                }
            }
        }
    }

    .select2-results__option--selected {
        background-color: var(--purple);
    }
}

.select2-dropdown {
    border: 1px solid var(--light-blue) !important;
    border-radius: 14px !important;
    overflow: hidden;
}

.select2-selection__clear {
    display: none;
}

.select2-container--open {
    .select2-selection__arrow {
        transform: rotate(180deg);
    }
    .select2-dropdown {
        top: 10px;
    }
}

/* Multi Select */

.comboTreeInputBox,
.comboTreeDropDownContainer input {
    padding: 10px 20px;
}

.comboTreeDropDownContainer {
    overflow: hidden;
    border-radius: 25px;
    top: 60px;
    width: max-content;

    ul {
        background: var(--white);
        padding: 14px 0;
    }

    li {
        position: relative;

        &:hover {
            background-color: transparent;
        }
    }

    .comboTreeItemTitle {
        position: relative;
        padding-left: 20px;

        input {
            position: absolute;
            top: 50%;
            left: 4px;
            transform: translateY(-50%);
            opacity: 0;
            cursor: pointer;
        }

        &::before {
            content: "";
            position: absolute;

            width: 12px;
            height: 12px;
            border: 1px solid var(--blue);

            left: 4px;
            top: 0;
            bottom: 0;
            margin: auto;

            display: flex;
            align-items: center;
            justify-content: center;

            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;

            font-size: 11px;
            color: var(--white);
        }

        &.comboTreeItemHover {
            background-color: transparent !important;
            border-radius: 0 !important;
            color: var(--dark-blue) !important;
        }

        &.selected {
            &::before {
                background-color: var(--blue);
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 12L10 18L20 6' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            }
        }
    }
}

.ComboTreeItemParent ul {
    padding: 0;
}

.multiplesFilter {
    display: none;
}

.comboTreeInputWrapper input {
    border: none;
    cursor: pointer;
}

.comboTreeInputBox {
    border-radius: 5px 5px 0 0;

    &:focus {
        border: none !important;

        & + .comboTreeArrowBtn {
            border: none !important;
            &:after {
                transform: rotate(180deg);
            }
        }
    }
}

.comboTreeArrowBtn {
    width: 26px;
    height: 20px;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);

    border-radius: 0;
    border: none;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: transparent !important;

    &::after {
        content: "";
        display: block;
        width: 12px;
        height: 8px;
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5.788' height='3.601' viewBox='0 0 5.788 3.601'%3E%3Cpath id='%D0%9A%D0%BE%D0%BD%D1%82%D1%83%D1%80_244' data-name='%D0%9A%D0%BE%D0%BD%D1%82%D1%83%D1%80 244' d='M7224.468 546.781l2.54 2.54 2.541-2.54' transform='translate(-7224.114 -546.427)' fill='none' stroke='%232650bc' stroke-width='1'/%3E%3C/svg%3E");
        background-color: var(--light-blue);
        transition: all 0.25s ease-in-out;
    }

    &.show {
        display: flex;
    }
}
