@import "./_breakpoints.pcss";

@define-mixin media $width {
    @media only screen and (max-width: $(width)px) {
        @mixin-content;
    }
}

@define-mixin min-media $width {
    @media only screen and (min-width: $(width)px) {
        @mixin-content;
    }
}

@define-mixin transition-all $time: 0.25s {
    transition: ease-in-out $time;
}

@define-mixin aspect-ratio $width, $height, $maxHeight: 9999px, $minHeight: 0px {
    position: relative;
    overflow: hidden;

    /* fix for hover scale on image on Safari 15.1 */
    mask-image: radial-gradient(white, black);

    &::before {
        content: "";
        display: block;
        padding-bottom: max(min(perc($height, $width), $maxHeight), $minHeight);
    }

    img {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
        top: 0;
        left: 0;
    }
}

@define-mixin max-line-length-one {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@define-mixin max-line-length $number {
    padding: 0 !important;
    display: -webkit-box;
    -webkit-line-clamp: $number;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@define-mixin transparent-bg-input $textColor: #fff {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: $textColor !important;
    }
}

/* For Safari overflow */
@define-mixin safari-overflow {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
}

/* Outline effect */
@define-mixin text-outline $stroke-color: var(--blue) {
    text-shadow: $stroke-color 5px 0px 0px,
        $stroke-color 4.90033px 0.993347px 0px,
        $stroke-color 4.60531px 1.94709px 0px,
        $stroke-color 4.12668px 2.82321px 0px,
        $stroke-color 3.48353px 3.58678px 0px,
        $stroke-color 2.70151px 4.20735px 0px,
        $stroke-color 1.81179px 4.6602px 0px,
        $stroke-color 0.849836px 4.92725px 0px,
        $stroke-color -0.145998px 4.99787px 0px,
        $stroke-color -1.13601px 4.86924px 0px,
        $stroke-color -2.08073px 4.54649px 0px,
        $stroke-color -2.94251px 4.04248px 0px,
        $stroke-color -3.68697px 3.37732px 0px,
        $stroke-color -4.28444px 2.57751px 0px,
        $stroke-color -4.71111px 1.67494px 0px,
        $stroke-color -4.94996px 0.7056px 0px,
        $stroke-color -4.99147px -0.291871px 0px,
        $stroke-color -4.83399px -1.27771px 0px,
        $stroke-color -4.48379px -2.2126px 0px,
        $stroke-color -3.95484px -3.05929px 0px,
        $stroke-color -3.26822px -3.78401px 0px,
        $stroke-color -2.4513px -4.35788px 0px,
        $stroke-color -1.53666px -4.75801px 0px,
        $stroke-color -0.560763px -4.96846px 0px,
        $stroke-color 0.437495px -4.98082px 0px,
        $stroke-color 1.41831px -4.79462px 0px,
        $stroke-color 2.34258px -4.41727px 0px,
        $stroke-color 3.17346px -3.86382px 0px,
        $stroke-color 3.87783px -3.15633px 0px,
        $stroke-color 4.4276px -2.32301px 0px,
        $stroke-color 4.80085px -1.39708px 0px,
        $stroke-color 4.98271px -0.415447px 0px;
}

@define-mixin text-outline-2 $stroke-color: var(--blue) {
    text-shadow: $stroke-color 2px 0px 0px,
        $stroke-color 1.75517px 0.958851px 0px,
        $stroke-color 1.0806px 1.68294px 0px,
        $stroke-color 0.141474px 1.99499px 0px,
        $stroke-color -0.832294px 1.81859px 0px,
        $stroke-color -1.60229px 1.19694px 0px,
        $stroke-color -1.97998px 0.28224px 0px,
        $stroke-color -1.87291px -0.701566px 0px,
        $stroke-color -1.30729px -1.5136px 0px,
        $stroke-color -0.421592px -1.95506px 0px,
        $stroke-color 0.567324px -1.91785px 0px,
        $stroke-color 1.41734px -1.41108px 0px,
        $stroke-color 1.92034px -0.558831px 0px;
}

/* Image cover */
@define-mixin img-fit {
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

/* Hover to links */
@define-mixin hover-link $color: #ffffff {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 1.3px;
        background: $color;
        @mixin transition-all;
    }

    &:hover {
        &::before {
            width: 100%;
            left: 0;
        }
    }
}
