/* Sorting component */
.sorting {
  width: fit-content;
  margin: 0 0 18px auto;
  display: flex;

  .sort-title {
    flex: 0 0 auto;
    margin-right: 15px;
  }

  span {
    font-size: 15px;
  }

  .select2-container {
    height: 20px;
    width: 100% !important;
  }

  .select2-dropdown {
    width: 150px !important;
    box-shadow: 1px 1px 7px rgb(0 162 255 / 20%);
    border: none !important;
  }

  .select2-container--default .select2-selection--single {
    display: flex;
    background: transparent;
    .select2-selection__arrow {
      position: relative;
      right: -5px;

      b:before {
        height: 5px;
      }
    }

    .select2-selection__rendered {
      white-space: normal;
      overflow: visible;
    }
  }
}
