/* Jobs Section */
.featured-jobs {
    padding: ac(140px, 220px, 375, 1025) 0 ac(100px, 0px, 551, 1440);

    &:before {
        background: url("../images/waves/1440_white.svg") repeat-x;
    }

    &:not(.wavy-blue) {
        &:before {
            top: -160px;
            transform: rotate(180deg);
        }
    }

    h2 {
        margin-bottom: ac(40px, 15px);
    }

    .cont {
        position: relative;
        z-index: 11;
    }

    .job-btn {
        margin: 50px auto 0;
    }

    &.wavy-blue {
        padding-top: ac(250px, 175px);
    }

    @mixin mob-lg {
        padding: ac(220px, 20px, 375, 551) 0 ac(0px, 80px, 375, 551);
    }

    @mixin desk-xl {
        padding-top: 220px;
    }
}

.job-card {
    background: var(--purple);
    padding: 35px 30px ac(65px, 45px);
    border-radius: 25px;
    max-width: 278px;
    min-height: ac(278px, 230px);
    position: relative;
    height: auto;

    h3 {
        font-size: ac(22px, 18px);
        font-weight: 400;
        color: var(--dark-blue);
        margin-bottom: 11px;
        font-family: var(--font-main);

        @mixin desk-xl {
            font-size: 25px;
        }
    }

    .details {
        p {
            font-family: var(--font-main);
            font-size: ac(15px, 12px);
            color: var(--blue);

            @mixin desk-xl {
                font-size: 18px;
            }
        }

        .place {
            line-height: 20px;
            margin: 8px 0;
        }
    }

    .btn {
        position: absolute;
        left: 30px;
        bottom: -20px;
    }

    @mixin desk-xl {
        max-width: 290px;
    }

    @mixin mob-md {
        max-width: 100%;
    }
}

/* Jobs Board */
.job-board {
    padding-bottom: ac(360px, 100px);
}

.job-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    grid-row-gap: 30px;
    grid-column-gap: 16px;

    margin-bottom: 86px;
    position: relative;

    .job-card {
        max-width: 100%;
    }

    h2 {
        position: absolute;
        font-size: ac(32px, 24px);
        width: 100%;
        text-align: center;
    }

    @mixin desk-sm {
        grid-template-columns: repeat(3, 1fr);
    }

    @mixin tab-md {
        grid-template-columns: repeat(2, 1fr);
    }

    @mixin mob-lg {
        grid-template-columns: 1fr;
    }
}

.job-list {
    .sorting {
        margin-bottom: 15px;
    }
}

/* Job Board */
.job-page {
    .cont {
        max-width: ac(1240px, 865px, 1440, 1920);
    }

    /* Bg */
    &:after {
        opacity: 0.8;
    }

    .job-details {
        display: flex;
        flex-direction: column;
        margin-bottom: 33px;

        h2 {
            font-size: ac(30px, 24px);
            margin-bottom: 26px;
        }

        span {
            font-size: ac(22px, 20px);

            &:not(:last-child) {
                margin-bottom: 9px;
            }
        }
    }

    .content-element {
        margin: 40px 0 33px;
    }
}
