/* Testimonials */

:root {
    --swiper-pagination-color: var(--white);
    --swiper-pagination-bullet-inactive-color: var(--dark-blue);
    --swiper-pagination-bullet-inactive-opacity: 0.1;
}

.testimonials {
    padding: ac(154px, 170px) 0 ac(200px, 100px);
    background: var(--light-blue);

    &:before {
        background: url("../images/waves/1440_white.svg") repeat-x;
    }

    &-swiper {
        max-width: 964px;
        margin: 0 auto;
        overflow: hidden;
        padding-top: 60px;
        position: relative;
    }

    .swiper-navigation {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
    }

    .swiper-btn-prev,
    .swiper-btn-next {
        color: var(--white);
        font-size: 18px;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        @mixin text-outline-2;

        display: flex;
        align-items: center;
        justify-content: center;

        /*    background: rgba(38,80,188,.6);*/
        z-index: 10;
        transition: color 0.3s ease;

        &:hover {
            color: var(--light-blue);
        }
    }

    .swiper-btn-next {
        transform: scale(-1) translateY(-1px);
        margin-left: 12px;
    }

    .swiper-slide {
        position: relative;
        max-width: 964px;

        .quote,
        .name {
            color: var(--white);
        }

        .quote {
            margin-bottom: 16px;
        }

        .name {
            font-size: ac(22px, 20px);
        }

        &:before,
        &:after {
            content: "\201C";
            color: var(--white);
            display: block;
            font-size: 115px;
            position: absolute;
            left: 0;
            top: -80px;
            pointer-events: none;
        }

        &:after {
            transform: scale(-1, 1);
            left: 45px;
        }
    }

    .swiper-pagination {
        bottom: -15px;
    }

    &.wavy-purple {
        padding: ac(230px, 165px) 0 80px;

        &:before {
            background: url("../images/waves/wave-purple-lg.svg") repeat-x;
        }
    }

    &.wavy-blue {
        padding: 145px 0 ac(180px, 80px);

        &:before {
            background: url("../images/waves/wave-blue-lg.svg") repeat-x;
        }
    }
}
