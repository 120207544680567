/* Insights section */
.insights-section {
    padding-top: ac(128px, 35px);

    h2 {
        margin-bottom: ac(32px, 40px);
    }

    @mixin tab-md {
        &:before {
            display: block;
        }
    }
}

/* Insights card */
.insight {
    &-card {
        max-width: 376px;
        padding: 30px 30px 50px;
        background: var(--white);
        border: 1px solid var(--blue);
        border-radius: 25px;
        position: relative;
        text-align: left;
        height: auto;

        .btn {
            position: absolute;
            left: 30px;
            bottom: -20px;
        }

        &:hover {
            img {
                transform: scale(1.1);
            }
        }
    }

    &-img {
        height: 202px;
        border-radius: 18px;
        margin-bottom: ac(20px, 15px);
        overflow: hidden;
        @mixin safari-overflow;

        img {
            border-radius: 18px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.3s ease;
        }
    }

    &-date {
        font-size: ac(15px, 12px);
        color: var(--blue);
        margin-bottom: ac(5px, 12px);

        @mixin desk-xl {
            font-size: 18px;
        }
    }

    &-excerpt {
        font-family: var(--font-main);
        font-size: ac(21px, 18px);
        color: var(--dark-blue);
        line-height: 140%;
    }
}

/* Insights List page */
.insights-hub {
    h1 {
        color: var(--dark-blue);
        margin-bottom: 35px;
    }

    .categories {
        display: flex;
        justify-content: center;
        margin-bottom: 25px;
        flex-wrap: wrap;

        .btn {
            margin: 10px;
        }
    }
}

.insights-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 35px;
    grid-column-gap: 16px;
    justify-items: center;

    margin-bottom: 85px;

    .insight-card {
        max-width: 100%;
        width: 100%;
    }

    @mixin media 1150 {
        grid-template-columns: repeat(2, 1fr);
    }

    @mixin tab-sm {
        grid-template-columns: 1fr;

        .insight-img {
            height: ac(300px, 200px, 375, 768);
        }
    }
}

/* Insights single page */
.insight-page {
    .date {
        font-size: ac(22px, 20px);
        margin: 27px auto;
        display: block;
        text-align: center;
    }

    .insight-img-wrap {
        margin-bottom: 40px;

        .insight-image {
            @mixin aspect-ratio 856, 500;
            margin-bottom: 14px;

            img {
                border-radius: 18px;
            }
        }

        .img-caption {
            color: var(--blue);
        }
    }
}

.insight-inner {
    max-width: ac(1240px, 865px, 1440, 1920);
    margin: 0 auto;
}

.share-block {
    margin-top: 20px;

    span {
        color: var(--blue);
        margin-bottom: 8px;
    }
}
