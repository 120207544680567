/* Job search form */
.job-search-form {
    max-width: 825px;
    margin: 46px auto 40px;
    padding: 30px 35px;
    background: var(--blue);
    border-radius: 25px;

    .form-wrap {
        display: flex;
        align-items: center;
        justify-content: center;

        &:nth-child(2) {
            /* max-width: 716px;*/
            margin: 30px auto 0;
        }
    }

    input {
        height: 50px;
        padding: 0 35px 0 20px;
        /* margin-right: 15px;*/
        font-weight: 500;

        background: var(--white);
        color: var(--dark-blue);
        border-radius: 25px;

        border: 1px solid var(--light-blue);

        &::placeholder {
            color: var(--dark-blue);
            font-weight: 500;
            font-family: var(--font-secondary);
            font-size: 15px;
            text-overflow: ellipsis;
        }

        @mixin mob {
            padding: 0 32px 0 15px;
        }
    }

    span {
        display: block;
    }

    .comboTreeDropDownContainer {
        width: 100%;
    }

    .select-item.type {
        max-width: 370px;

        .select2-selection {
            overflow-x: auto;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .btn {
        background: var(--purple);
        color: var(--blue);
        max-width: 150px;
        grid-column: 1 / -1;
        margin: 30px auto 0;
    }

    /* Select and Multi select*/
    .select-item,
    .multi-select-item,
    .multi-checkbox-item {
        /*max-width: 230px;*/
        width: 100%;

        &:not(:last-child) {
            margin-right: 15px;
        }

        &.salary {
            max-width: 190px;
        }

        &.day-rate {
            max-width: 150px;
        }

        &.specialism {
            justify-self: end;
        }
    }

    .select2-container--default .select2-selection {
        height: 50px;
        display: flex;
        align-items: center;
        border-radius: 25px !important;
        padding: 0 35px 0 20px;

        @mixin mob {
            padding: 0 32px 0 15px;
        }

        .select2-selection__rendered {
            padding: 0;
            font-size: 15px;
            font-weight: 500;
            font-family: var(--font-secondary);
            color: var(--dark-blue);
            -ms-text-overflow: ellipsis;
            text-overflow: ellipsis;
        }

        .select2-selection__arrow {
            right: 16px;
            top: 10px;

            b:before {
                background-color: var(--light-blue);
            }
        }
    }

    .comboTreeDropDownContainer {
        border: none !important;
        box-shadow: 1px 1px 7px rgb(0 162 255 / 20%);

        .dropdown-wrapper {
            overflow-x: hidden;
            overflow-y: auto;
            max-height: 260px;

            &::-webkit-scrollbar {
                width: 4px;
                height: 20px;
            }

            &::-webkit-scrollbar-track {
                background: var(--grey);
            }

            &::-webkit-scrollbar-thumb {
                background: var(--light-blue);
            }
        }
    }

    .comboTreeArrowBtn {
        width: 16px;
        right: 16px;
    }

    /* Border */
    .select2-selection--single,
    input {
        border: 1px solid var(--light-blue);
    }

    @mixin tab-md {
        input,
        .select-item.type {
            max-width: 100%;
        }

        .form-wrap {
            flex-direction: column;

            /*.keyword-input {
        margin: 0 0 30px;
      }*/
        }

        .select2-container {
            max-width: 100%;
        }

        .select-item,
        .multi-select-item,
        .multi-checkbox-item {
            max-width: 100%;

            &.salary {
                max-width: 100%;
            }

            &.day-rate {
                max-width: 100%;
            }

            &:not(:last-child) {
                margin: 0 0 30px;
            }
        }

        .comboTreeDropDownContainer {
            width: 100%;
        }

        .comboTreeArrowBtn {
            right: 16px;
        }
    }
}

.select2-results__options {
    &::-webkit-scrollbar {
        width: 4px !important;
        height: 20px !important;
    }

    &::-webkit-scrollbar-track {
        background: var(--grey) !important;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--light-blue) !important;
    }
}

/* CV section */
.cv-section {
    padding-top: 74px;

    @mixin mob-lg {
        padding-top: 0;

        .cont {
            width: 100% !important;
        }
    }
}

.cv-form {
    text-align: center;
}

.cv-block {
    padding: 80px ac(98px, 30px, 551, 1440);
    color: var(--white);
    background: linear-gradient(114deg, #08c757 0%, #00a2ff 100%);
    border-radius: 25px;

    .heading-section {
        max-width: 580px;
        text-align: center;
        margin: 0 auto 35px;

        h2 {
            margin-bottom: 30px;
        }
    }

    input,
    textarea {
        border-color: var(--white);
        color: var(--white);

        @mixin transparent-bg-input var(--white);

        &::placeholder {
            color: var(--white);
        }
    }

    .inputs-wrap {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 32px;

        .input-file {
            grid-column: -1 / 1;
        }

        @mixin media 991 {
            grid-template-columns: 1fr;
            justify-items: center;

            .input-file {
                grid-column: auto;
            }
        }
    }

    .input-file {
        display: flex;
        align-items: center;
        margin: 0 auto;

        .file-btn {
            position: relative;
            width: 105px;
            height: 35px;
            flex: 0 0 auto;

            display: flex;
            align-items: center;
            justify-content: center;

            background: var(--white);
            border: 1px solid var(--grey);
            border-radius: 3px;
            margin-right: 12px;
            transition: all 0.3s ease;

            input {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                opacity: 0;
                font-size: 0;
                padding: 0;
                cursor: pointer;
            }

            label {
                color: var(--dark-blue);
                font-size: 12px;
                line-height: normal;
            }

            &:hover {
                border-radius: 25px;
            }
        }

        .file-name {
            font-size: 15px;
        }

        @mixin mob-lg {
            flex-direction: column;

            .file-btn {
                margin: 0 0 16px;
            }
        }
    }

    .agreement {
        width: fit-content;
        margin: 30px auto;

        .checkbox {
            span,
            span a {
                color: var(--white);
            }

            a:hover {
                color: var(--blue);
            }

            span {
                &:before {
                    border-color: var(--white);
                }
            }

            input:checked + span {
                &:before {
                    background-color: var(--white);
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 12L10 18L20 6' stroke='%23030E28' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                }
            }
        }
    }

    .btn {
        margin: 0 auto;
    }

    @mixin mob-lg {
        border-radius: 0;
    }
}
