/* Team section */
.team {
    padding: ac(130px, 170px) 0 ac(120px, 100px);
    background: var(--purple);

    &:before {
        background: url("../images/waves/1440_white.svg") repeat-x;
    }

    .heading-section {
        max-width: 580px;
        margin-bottom: 52px;

        h2 {
            margin-bottom: 10px;
        }
    }
}

.members-list {
    display: grid;
    max-width: 965px;
    margin: 0 auto;
    grid-column-gap: 16px;
    grid-row-gap: 50px;
    grid-template-columns: repeat(3, 1fr);

    @mixin media 1024 {
        grid-template-columns: repeat(2, 1fr);
    }

    @mixin mob-xl {
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
    }
}

.member {
    &-card {
        max-width: 310px;
        width: 100%;

        .trigger-title {
            transition: 0.2s ease;
            color: var(--dark-blue);
            margin-left: 8px;
        }

        .tab-item.is-open,
        .tab-item:hover {
            .trigger-img {
                &:before,
                &:after {
                    background: var(--blue);
                }
            }

            .trigger-title {
                color: var(--blue);
            }
        }

        @mixin media 1024 {
            max-width: 100%;
        }
    }

    &-img {
        height: 23vw;
        margin-bottom: 30px;
        min-height: 275px;
        @mixin img-fit;

        @mixin min-media 1440 {
            height: 330px;
        }

        @mixin mob-xl {
            min-height: 300px;
        }

        /* For Safari */
        transform: translateZ(0);
        will-change: transform;
    }

    &-info {
        h3 {
            font-size: ac(30px, 24px);
        }

        h4 {
            font-size: ac(22px, 20px);
        }

        .position {
            margin: 5px 0 18px;
        }

        .contacts {
            margin-bottom: 7px;
            a {
                width: fit-content;
                color: var(--dark-blue);

                &:before {
                    background: var(--dark-blue);
                }

                &:not(:last-child) {
                    margin-bottom: 5px;
                }
            }
        }

        i {
            transition: color 0.25s ease;
        }
    }
}

.simplebar-scrollbar::before {
    background-color: var(--blue);
    width: 3px;
}

.clip-1 {
    clip-path: url(#type-1);
}

.clip-2 {
    clip-path: url(#type-2);
}

.clip-3 {
    clip-path: url(#type-3);
    img {
        object-position: top;
    }
}
