/* Specialisms */
.specialisms {
    padding: ac(130px, 10px) 0 ac(130px, 35px);

    &:before {
        background: url("../images/waves/wave-purple-lg.svg") repeat-x;
    }

    h2 {
        margin-bottom: 40px;
    }

    .spec {
        &-items {
            display: grid;
            grid-auto-flow: column;
            grid-gap: ac(16px, 30px);
            align-items: flex-end;
            position: relative;
            z-index: 11;
        }

        &-item {
            display: flex;
            align-items: center;
            justify-content: center;

            max-width: 376px;
            width: 100%;
            min-height: 29vw;
            border-radius: 25px;
            border: 1px solid var(--blue);

            overflow: hidden;
            cursor: pointer;
            position: relative;

            h2 {
                font-size: ac(30px, 24px);
                color: var(--white);
                position: absolute;
                bottom: 30px;
                left: 35px;
                margin: 0;
                z-index: 10;
                transition: all 0.3s ease;
            }

            /* Hover overlay */
            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, #3b45d7 0%, #e179d5 100%);
                mix-blend-mode: multiply;
                border-radius: 25px;
                pointer-events: none;
                opacity: 0;
                z-index: 5;
                transition: opacity 0.3s ease;
            }

            .image {
                position: absolute;
                width: 100%;
                height: 100%;
                @mixin img-fit;

                /* Image overlay */
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background: var(--dark-blue);
                    border-radius: 25px;
                    pointer-events: none;
                    opacity: 0.4;
                    z-index: 5;
                    transition: opacity 0.3s ease;
                }
            }

            .spec-list {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 10;

                opacity: 0;
                pointer-events: none;
                transition: all 0.3s ease;

                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;

                ul {
                    overflow-y: scroll;
                    width: 100%;
                    text-align: center;
                    padding: 20px 0;

                    &::-webkit-scrollbar {
                        display: none;
                    }
                }

                li {
                    color: var(--white);
                    &:not(:last-child) {
                        margin-bottom: 8px;
                    }
                }
            }

            &:hover {
                border: none;
                &:before {
                    opacity: 1;
                }

                .spec-list {
                    opacity: 1;
                    pointer-events: auto;
                }

                .spec-cont {
                    width: 100%;
                    height: 100%;

                    &:before {
                        opacity: 0;
                    }
                }

                h2 {
                    opacity: 0;
                }
            }
        }

        &-cont {
            border-radius: 25px;
            overflow: hidden;
            position: absolute;

            width: 85%;
            height: 85%;
            transition: all 0.3s ease;
        }

        @mixin tab {
            &-items {
                grid-auto-flow: row;
            }

            &-item {
                max-width: 100%;
                min-height: ac(400px, 320px, 375, 1024);
            }
        }

        @mixin tab-sm {
            &-items {
                grid-template-columns: 1fr;
            }
        }
    }

    &.client {
        padding-top: ac(210px, 100px);
    }

    &.wavy-blue {
        padding-top: ac(250px, 175px);
    }

    @mixin desk-md {
        .spec-item {
            min-height: 420px;
        }
    }

    @mixin tab {
        .cont {
            max-width: 700px;
        }
    }
}
