.solutions {
    padding: 150px 0 ac(110px, 70px);
    background: var(--purple);

    .heading-section {
        max-width: 675px;
        margin-bottom: 40px;

        @mixin min-media 1920 {
            max-width: 730px;
        }
    }

    @mixin tab {
        .heading-section {
            margin: 0 0 30px;
            max-width: 580px;
        }

        .content-wrap {
            flex-direction: column;
        }
    }
}

.solutions-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    width: 100%;

    @mixin media 1025 {
        grid-template-columns: 1fr;
    }
}

.solution {
    padding: 30px 15px ac(25px, 15px) ac(30px, 20px);
    border-radius: 25px;
    background: linear-gradient(114deg, #08c757, #00a2ff);
    transform-style: preserve-3d;

    .simplebar-scrollbar::before {
        background-color: var(--white);
    }

    &__inner {
        transform: translateZ(25px);
    }

    .solution-trigger {
        padding-right: 30px;
        background: transparent;
        border: none;
        width: 100%;
        transition: all 0.2s ease-in-out;
    }

    h3 {
        font-size: ac(30px, 22px);
        color: var(--white);
        margin-bottom: 12px;

        @mixin mob {
            font-size: 18px;
        }
    }

    &-content {
        max-height: 300px;
        padding-right: 20px;

        p {
            line-height: 25px;
            color: var(--white);
        }
    }

    &.is-open {
        background: var(--white);

        button {
            padding-bottom: 0;
        }
    }
}

.is-open {
    .trigger-img {
        &:before {
            opacity: 0;
        }

        &:after {
            transform: translate(0) !important;
        }
    }
}

.trigger-img {
    position: relative;
    margin-left: auto;

    &:before,
    &:after {
        content: "";
        display: block;
        width: 13px;
        height: 3px;
        background: var(--dark-blue);
        border-radius: 50px;
        transition: ease 0.2s;
    }

    &:after {
        margin-top: -3px;
        transform: rotate(90deg);
    }
}
